import React, { useState, useEffect } from "react"
import { useIntl } from "gatsby-plugin-intl"

import SEO from "../components/seo"
import Form from '../components/hoc/Form/Form'


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import loginAction from '../state/async/authLogin';
import {isLoggedIn, getData, loginPending, getErrors, formError, getAccessToken, getRefreshToken} from '../state/reducers/auth';
import { navigate } from "gatsby-plugin-intl/link"
// import { useStateValue } from '../state/store-provider';
// import { useStore } from '../state/store-provider';

const Login = ( props ) => {
    const i18n = useIntl().formatMessage

    const rawError = props.error
    let error

    if(rawError === 'No active account found with the given credentials') {
        error = i18n({ id: "pages.login.errors.account" })
    } else if(Array.isArray(rawError)) {
        error = rawError.join('. ')
    } else if(rawError) {
        error = rawError
    }

   
    useEffect(() => {
        console.log('Login props: ', props)
        
        if(props.isLoggedIn) {
            navigate('/app')
        }
    }, [props])

    const submitHandler = async (submission) => {
        console.log('submitted: ', submission)
        await props.login(submission.email, submission.password, submission.remember)
        console.log(props)
    }

    return (
    <>
        <SEO title="Login" />
        <section className="section-padding-big">
            <div className="container">
                <div className="row center-xs">
                <div className="col col-xs-12 col-md-6">
                    
                    <div className="card">
                    <h1 className=" h3">{ i18n({ id: "pages.login.title" })}</h1>
                    <p>{ i18n({ id: "pages.login.description" })}</p>
                        <Form 
                            loading={props.loginPending}
                            success={props.isLoggedIn}
                            submitHandler={submitHandler}
                            submitText={ i18n({ id: "general.login" }) }
                            formElements={[
                                { type: 'email', name: 'email', label: 'Email', required: true, value: '' },
                                { type: 'password', name: 'password', label: 'Password', required: true, value: '' },
                                { type: 'checkbox', name: 'remember', label: i18n({ id: "general.remember_me" }), required: false, value: '' }
                            ]}
                            linkCaption={ i18n({ id: "general.forgot_password" }) }
                            link="/reset-password"
                            linkTitle={`${ i18n({ id: "general.change_password" }) } →`}
                            error={error ? error : null}
                            formError={props.formError}
                        />
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
    )
}

// export default Login

const mapStateToProps = state => ({
    error: getErrors(state.auth),
    data: getData(state.auth),
    isLoggedIn: isLoggedIn(state.auth),
    access: getAccessToken(state.auth),
    refresh: getRefreshToken(state.auth),
    loginPending: loginPending(state.auth),
    formError: formError(state.auth)
})

const mapDispatchToProps = dispatch => bindActionCreators({
    login: loginAction,
}, dispatch)

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
